import { version } from './version';

export const environment = {
  BASE_URL: 'https://dtepraiagrande.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3541000',
  schema: 'pbi_dte_praiagrande',
  groupId: 'c35d79ba-815c-4b39-9250-91aec561f39c',
  analiseGeral: '4208c6e1-dcab-4b64-aab9-5a6a60dd448b',
  analiseUsuarios: 'ee1cd1c7-fdcb-455d-a763-07b88036607d',
  analiseComunicados: '143fb0cf-f933-4780-93ca-8b345be60c22',
  analiseProcurador: '5977cb8e-271c-420e-a962-33714a139842',
  analiseEventos: '555ca9a1-9e9d-4b06-9c33-64f7cad1e924'
};
